<template>
  <div class="promotions-dialog">
      <div class="promotions-dialog-box">
        <div class="promotions-dialog-box-header">
            <h1>הטבות לקוח</h1>
            <div @click="handle_close" class="exit-btn">X</div>
        </div>
        <div class="promotions-dialog-box-content">
            <div class="promotions-dialog-box-content-header">
                <h2>רשימת "מתנות" שהלקוח זכאי אליהן</h2>
            </div>
            <div class="promotions-dialog-box-content-list">
            <template v-for="promotion in promotions" :key="promotion.uid">
                <div class="promotion">
                    <div class="promotion-details">
                        <div class="promotion-details-title">
                            <h3>{{promotion.title}}</h3>
                        </div>
                        <div class="promotion-details-content">
                            <p class="content-text"><span style="font-weight:500;">מתאריך:</span> {{new Date(promotion.from_date.seconds * 1000).toLocaleDateString('he')}}</p>
                            <p  class="content-text"><span style="font-weight:500;">עד תאריך:</span> {{new Date(promotion.to_date.seconds * 1000).toLocaleDateString('he')}}</p>
                        </div>
                    </div>
                    <div class="promotion-actions">
                        <el-button @click="handle_promotion_click(promotion)" style="background:#7367f0; border:0;" type="primary">ממש</el-button>
                    </div>
                </div>
            </template>
            </div>
            <component v-if="state" :promotion="selected_promotion" @finish="handle_close" @cancel="state=null; selected_promotion=null;" :is="state"/>
        </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import ConfirmationDialog from './Promotions/ConfirmationDialog.vue'
import ConfirmationDialogImage from './Promotions/ConfirmationDialogImage.vue'
import ConfirmationDialogEmail from './Promotions/ConfirmationDialogEmail.vue'
import ConfirmationDialogSms from './Promotions/ConfirmationDialogSms.vue'
export default {
    components:{ConfirmationDialog,ConfirmationDialogImage,ConfirmationDialogEmail,ConfirmationDialogSms},
    props:['promotions'],
    emits:['close'],
    setup(_,{emit}){
        const state = ref(null)
        const selected_promotion = ref(null)
        const handle_promotion_click=(promotion)=>{
            selected_promotion.value = promotion
            switch (selected_promotion.value.confirmation_type) {
                case 'תמונה':
                    console.log('אני תמונה');
                    state.value = 'ConfirmationDialogImage'
                    break;
                case 'ללא':
                    state.value = 'ConfirmationDialog'
                    break;
                case 'אימייל':
                    state.value = 'ConfirmationDialogEmail'
                    break;
                case 'סמס':
                    state.value = 'ConfirmationDialogSms'
                    break;
            }
        }
        const handle_close = ()=>{
            emit('close')
        }
        return{
            handle_close,
            handle_promotion_click,
            selected_promotion,
            state
        }
    }
}
</script>

<style scoped>
    .promotions-dialog{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 11;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 5px;
    }
    .promotions-dialog-box{
        width: 100%;
        max-width: 500px;
        height: 500px;
        border-radius: 10px;
    }
    .promotions-dialog-box-header{
        position: relative;
        width: 100%;
        height: 60px;
        background: var(--success);
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.61);
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        border-radius: 50%;
        z-index: 12;
        cursor: pointer;
        background: var(--danger);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;    
        font-size: 20px;
        font-weight: 500;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
    }
    .promotions-dialog-box-content{
        position: relative;
        width: 100%;
        height: calc(100% - 50px);
        background: #fff;
        border-radius: 0 0 10px 10px;
    }
    .promotions-dialog-box-content-header{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.61);
    }
    .promotions-dialog-box-content-list{
        padding: 0 5px;
        width: 100%;
        height: calc(100% - 80px);
        overflow:hidden;
        overflow-y: auto;
    }
    .promotion{
        width: 100%;
        height: 100px;
        border-bottom: 1px solid lightgray;
        display: flex;
        border: 1px solid lightgray;
        border-radius: 5px;
        margin-bottom: 10px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.26);
    }
    .promotion-details{
        width: 70%;
        height: 100%;
    }
    .promotion-details-title{
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgray;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.61);
    }
    .promotion-details-content{
        width: 100%;
        height: 70%;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .content-text{
        margin-top:5px;
        font-size: 18px;
    }
    .promotion-actions{
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid lightgray;
    }
   
</style>