<template>
    <div class="item scale-in-center" :style="check_frame()">
        <div class="item-discounts" v-if="item.prices && (!user || user.assortment == '10001') && item.prices.length > 0" style="background:var(--purple)">
            <p>הנחת כמות</p>
        </div>
        <div class="item-discounts" v-if="item.sale">
            <i class="material-icons" style="margin-left: 5px; font-size: 14px;">sell</i>
            <p>מבצע</p>
        </div>
        <div class="makat">
            {{item.makat}}
        </div>
        <div @click="handle_edit_item" v-if="user_role>=99" class="edit">
            <i class="material-icons">edit</i>
        </div>
        <div class="item-image">
            <div class="expired-date" v-if="item.expiry_date">
                    תוקף: {{item.expiry_date}}
            </div>
            <div class="note" v-if="item.note && item.note.length > 0">
                {{item.note}}
            </div>
            <div class="fixed-price" v-if="item.fixed_price && item.fixed_price.length > 0">
                {{item.fixed_price}}
            </div>
            <div class="order-amount" v-if="item.order_amount > 0">
                <p>{{item.order_amount}}</p>
                <p style="font-size: 10px;">יחידות</p>
            </div>
            <img :src="item.image" alt="">
        </div>
        <div class="out-of-stock" v-if="item.showOnWeb == 'N'">
            <p>חסר זמנית במלאי</p>
        </div>
        <div class="item-name">
            <p v-if="!item.new_name">{{item.name}}</p>
            <p v-else>{{item.new_name}}</p>
        </div>
        <div class="item-stats">
            <div class="box-amount">
                <i class="material-icons" style="margin-left: 5px;">margin</i>
                <p>{{item.boxSize}} {{item.measure}}</p>
            </div>
            <div class="box-amount">
                <i class="material-icons" style="margin-left: 5px;">add_shopping_cart</i>
                <p v-if="item.min_order">{{item.min_order}}</p>
                <p v-else>{{item.boxSize}} {{item.measure}}</p>
            </div>
            <div class="item-kosher">
                <img src="@/assets/kosher_symbol.png" alt="" v-if="item.kosher_type != 'לא כשר' && item.kosher_type != 'ללא כשרות'"/>
                <img v-if="item.kosher_type == 'לא כשר'" src="@/assets/images/not-kosher.jpeg" alt="" style="width:25px; height:auto;"/>
                <p v-if="item.kosher_type != 'לא כשר' && item.kosher_type != 'ללא כשרות'">
                    כשר
                </p>
                <p v-if="item.kosher_type == 'לא כשר'">ללא</p>
                <p v-if="item.kosher_type == 'ללא כשרות'">
                    <i class="material-icons">
                    check_box_outline_blank
                    </i>
                </p>
            </div>
        </div>
        <div class="add-to-order">
            <el-button type="primary" style="height:100%; width:70%;" @click="open_item">הוסף להזמנה</el-button>
        </div>
    </div>
</template>

<script>
import store from '../../store'
import {not_loging_msg} from '../../Methods/Msgs'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
export default {
props:["item", "user"],
emits:["open_item","edit_item"],
setup(props, {emit}){

    const user_role = ref(computed(()=>{
        return store.state.role
    }))

    const handle_edit_item = ()=>{
        emit('edit_item')
    }
    const check_frame = ()=>{
        switch (props.item.frame) {
            case 'אדום':
                return 'border:5px solid red'
            case 'ירוק':
                return 'border:5px solid green'
            case 'כחול':
                return 'border:5px solid blue'

            default:
                break;
        }
    }
    const open_item = () => {
        if(store.state.user){
            emit("open_item")
        }else{
            not_loging_msg('משתמש לא מחובר','על מנת להוסיף מוצרים להזמנה יש להתחבר תחילה')
        }
    }

   
    return{
        open_item,
        check_frame,
        user_role,
        handle_edit_item
    }
}
}
</script>

<style scoped>
.item{
    position: relative;
    background: white;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    height: 100%;
}
.item-image{
    width: 100%;
    height: 210px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.item-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.item-name{
    width: 100%;
    height: 30px;
    white-space: nowrap;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.item-name P{
    font-weight: 400;
}
.item-stats{
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
}
.item-stats i{
    color: rgba(0, 0, 0, 0.664);
}
.makat{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    width: fit-content;
    padding: 8px;
    text-align: center;
    background: var(--success);
    border-radius: 5px;
    font-weight: 400;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.822);
}
.edit{
    position: absolute;
    top: 50px;
    right: 5px;
    z-index: 1;
    width: fit-content;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    background: var(--warning);
    border-radius: 50%;
    font-weight: 400;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.822);
    cursor: pointer;
}
.edit i{
    border-radius: 50%;
}
.fixed-price{
    position: absolute;
    bottom: 0;
    left: 5px;
    z-index: 1;
    width: fit-content;
    padding: 8px;
    text-align: center;
    background: var(--purple);
    border-radius: 5px;
    font-weight: 400;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.822);
}
.note{
    position: absolute;
    bottom: 50px;
    left: 5px;
    z-index: 1;
    width: 35%;
    overflow:hidden;
    padding: 8px;
    text-align: center;
    background: var(--purple);
    border-radius: 5px;
    font-weight: 400;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.822);
}
.expired-date{
    position: absolute;
    bottom: 0;
    right: 5px;
    z-index: 1;
    width: fit-content;
    padding: 8px;
    text-align: center;
    background: lightseagreen;
    border-radius: 5px;
    font-weight: 400;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.822);
}
.box-amount{
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.item-kosher{
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.item-kosher img{
    max-width: 40px;
    max-height: auto;
    margin-left: 5px;
}
.add-to-order{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    flex-shrink: 0;
    padding: 5px;
}
.item-discounts{
    position: absolute;
    left: 5px;
    top: 5px;
    width: 120px;
    border-radius: 5px;
    height: 35px;
    font-size: 16px;
    background: var(--danger);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.out-of-stock{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.479);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    color: white;
    text-shadow: 0 0 5px black;
    font-size: 24px;
}
.order-amount{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--yellow);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 6px rgba(128, 128, 128, 0.892);
    flex-direction: column;
    z-index: 2;
}
</style>