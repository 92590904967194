<template>
    <div class="catalog">
        <div class="search-panel" v-if="toggle_search">
            <div class="search-main">
                <div class="close-search" @click="toggle_search = !toggle_search">
                    <i class="material-icons">close</i>
                </div>
                <div class="search-title">
                    <p>חיפוש מוצרים</p>
                </div>
                <div class="search-bar">
                    <el-input placeholder="חיפוש..." v-model="search_prod_string"></el-input>
                </div>
                <template v-for="item in product_search_results" :key="item.makat">
                    <div class="item-result-line">
                        <div class="item-res-image">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="item-res-data">
                            <p style="font-size: 20px; color:var(--yellow);">{{item.name}}</p>
                            <p>מק"ט: {{item.makat}}</p>
                            <p>ברקוד: {{item.barcode}}</p>
                        </div>
                        <div class="open-res-item" @click="selected_product = item">
                            <p>בחר</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <AddItemToCart :product="selected_product" v-if="selected_product" @close="selected_product = null" @open_new="change_product"/>
        
        <div class="title">
            <div class="categories-btn" @click="toogle_menu =!toogle_menu">
                <i class="material-icons" style="margin-left: 5px;">list</i>
                <p>קטגוריות</p>
            </div>
            <p>קטלוג רוסמן</p>
            <div class="search-btn" @click="toggle_search = !toggle_search">
                <i class="material-icons" style="margin-left: 5px;">search</i>
                <p>חיפוש</p>
            </div>
        </div>

        <div class="categories-menu" v-if="toogle_menu">
            <Categories @select_category="select_category" @select_sub="select_sub" :selected_sub="selected_sub" :selected_category="selected_category"
            ref="categories_ref"/>
        </div>
        
        <div class="products-grid" id="products-grid">
            <div class="search-bar">
                <div class="not-selected-sub" v-if="!selected_category">
                    <p>מבצעים</p>
                </div>
                <div class="prev-sub-btn" v-if="selected_category" @click="prev_btn">
                    קודם
                </div>
                <div class="selected-sub" v-if="selected_category">
                    <p style="white-space:nowrap">{{selected_category.name}} </p>
                    <i class="material-icons" style="margin-left: 5px; margin-right: 5px; font-size:14px;" v-if="selected_sub">west</i>
                    <p>{{selected_sub}}</p>
                </div>
                <div @click="is_cosher=true" class="is-cosher-btn">
                    כשר
                </div>
                <div  @click="is_cosher=false" class="is-not-cosher-btn">
                    הכל
                </div>
                <div class="next-sub-btn" @click="next_btn">
                    הבא
                </div>
            </div>
            <!-- flayer here-->

            <div  class="products" id="products">
                <template v-for="item in products" :key="item.makat">
                    <ProductCard @click="stam(item)" @edit_item="selected_edit_item=item"  :item="item" :user="user" @open_item="selected_product = item"/>
                </template>
                <template v-if="!products || products.length == 0">
                    <template v-if="!selected_category">
                        <template v-for="(sale,index) in sales" :key="index">
                            <SaleCard :sale="sale" @open_sale="open_sale"/>
                        </template>
                    </template>
                    <div class="empty-cat" v-if="selected_category">
                        <img src="https://image.flaticon.com/icons/png/512/5089/5089733.png" alt="">
                        <p class="cat-name">{{selected_category.name}}</p>
                        <p>מחלקה ריקה או סגורה במגוון</p>
                    </div>
                </template>
            </div>
        </div>

        <div @click="handle_gift" v-if="promotions.length>0" class="gift slide-in-top">
            <img  src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/gifs%2Fgiphy.gif?alt=media&token=22763fa4-3a85-4829-8daf-e5612ec84ed1" alt="">
        </div>

        <PromotionsDialog v-if="show_promotions_dialog" :promotions="promotions" @close="show_promotions_dialog=false" />
        <ItemExamine v-if="selected_edit_item" @close="selected_edit_item=null" :product="selected_edit_item" />
        <DisplayFlayer v-if="show_flayer" @close="show_flayer=false" :flayer_images="selected_flayer_urls"/>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import { ref } from '@vue/reactivity';
import Categories from '../components/Catalog/Categories.vue';
import { computed, onMounted, watch } from '@vue/runtime-core';
import store from '../store';
import AddItemToCart from '../components/Catalog/AddItemToCart.vue';
import ProductCard from '../components/Catalog/ProductCard.vue';
import Swal from 'sweetalert2';
import SaleCard from '../components/Catalog/SaleCard.vue';
import { onBeforeRouteLeave } from 'vue-router';
import {init_catalog,init_flayers} from '../Methods/Catalog';
import PromotionsDialog from '../components/Catalog/PromotionsDialog.vue'
import ItemExamine from '../components/Admin/Catalog/ItemExamine.vue'
import DisplayFlayer from '../components/Catalog/DisplayFlayer.vue'

export default {
components:{Categories, AddItemToCart, ProductCard, SaleCard,PromotionsDialog,ItemExamine,DisplayFlayer},
setup(){

    useMeta({
      title: 'קטלוג',
      htmlAttrs: { lang: 'he', amp: true },
      meta:[
        {
            name:'description',
            content:`
                רוסמן לוגיסטיק - קטלוג מוצרים
            `
        },
        {
            name:'keywords',
            content:`
                רוסמן קטלוג, rosman catalog, מוצרים
            `
        },
      ]
    })
    
    const is_cosher = ref(false)

    const show_flayer = ref(false)
    const selected_flayer_urls = ref([])

    const flayers = ref(null)
    const selected_edit_item = ref(null)
    const show_promotions_dialog = ref(false)
    const promotions = ref(computed(()=>{
        return store.state.client.promotions
    }))

    const handle_gift = ()=>{
        show_promotions_dialog.value = true
    }
    const toggle_search = ref(false);
    const search_prod_string = ref('');

    const product_search_results = ref(computed(() => {
        if(search_prod_string.value && store.getters.products){
            let prods =  store.getters.products
            return prods.filter(product => {
                return product.name.includes(search_prod_string.value) ||  product.makat.includes(search_prod_string.value) ||  product.barcode.includes(search_prod_string.value)
            })
        }
    }))

    const user = ref(computed(() => {
        return store.getters.user
    }))

    const categories = ref(computed(() => {
        let cats =  store.getters.departments
        if(cats){
            cats.forEach(cat => {
                cat.subs = cat.subs.filter(sub => {
                    return store.state.t_subs.includes(sub)
                })
            })
            return cats
        }
    }))

    const toogle_menu = ref(false);

    watch(toogle_menu, () => {
        const el = document.getElementById('products-grid')
        if(toogle_menu.value){
            if(el){
                el.style.width = 'calc(100% - 375px)';
                el.style.right = '375px';
            }
        }else{
            if(el){
                el.style.width = 'calc(100%)';
                el.style.right = '0';
            }
        }
    })
    
    const stam = (item)=>{
        console.log(item);
    }
    const selected_category = ref(null);
    const selected_sub = ref(null);

    const products = ref(computed(() => {
        let _products = store.getters.products
        if(selected_sub.value){
            let items = _products.filter(item => {
                return item.sub_category == selected_sub.value
            })
            items = items.sort((aa,bb) => {
                if(aa.order < bb.order){return -1}
                if(aa.order > bb.order){return 1}
            })
            //dd
            items = items.filter(item => {
                if(is_cosher.value && (!item.kosher_type || (item.kosher_type != 'לא כשר' && item.kosher_type != 'ללא כשרות'))){
                    console.log('check:',item.kosher_type);
                    return item
                }
                if(!is_cosher.value){
                    console.log('check:',item.kosher_type);
                    return item
                }
            })
            return items
        }
        if(selected_category.value){
            let items = _products.filter(item => {
                return item.category == selected_category.value.uid
            })
            items = items.sort((aa,bb) => {
                if(aa.order < bb.order){return -1}
                if(aa.order > bb.order){return 1}
            })
            //ss
            items = items.filter(item => {
                if(is_cosher.value && (!item.kosher_type || item.kosher_type != 'לא כשר', item.kosher_type != 'ללא כשרות')){
                    return item
                }
                if(!is_cosher.value){
                    return item
                }
            })
            return items
        }
        // return _products
        return
    }))

    const select_category = (e) => {
        if(e){
            var categories = store.getters.departments;
            var inx = categories.findIndex(cat => {
                return cat.uid == e
            })
            if(inx != -1){
                selected_category.value = categories[inx];
            }
        }else{
            selected_category.value = null;
        }

    }

    const categories_ref = ref(null)
    const select_sub = (e) => {
        selected_sub.value = e
        if(window.innerWidth < 900){
            toogle_menu.value = false;
        }
    }

    const next_btn = () =>{
        if(selected_category.value){
            let inx = categories.value.findIndex(cat => {
                return cat.uid == selected_category.value.uid
            })
            if(!selected_sub.value){
                if(inx != -1){
                    if(categories.value[inx].subs[0]){
                        selected_sub.value = categories.value[inx].subs[0];
                    }
                }
            }else{
                let inx_sub = categories.value[inx].subs.findIndex(sub => {
                    return sub == selected_sub.value
                })
                if(inx_sub != -1){
                    if(inx_sub != categories.value[inx].subs.length - 1){
                        selected_sub.value = categories.value[inx].subs[inx_sub + 1]
                        if(categories_ref.value){
                            categories_ref.value.selected_sub = selected_sub.value;
                        }
                    }
                    else{
                        if(categories.value[inx + 1]){
                            selected_category.value = categories.value[inx + 1]
                            selected_sub.value = selected_category.value.subs[0];
                            if(categories_ref.value){
                                categories_ref.value.selected_category = selected_category.value.uid;
                            }
                        }else{
                            Swal.fire({
                                icon: 'info',
                                title: 'סוף קטלוג',
                                text: 'ברכות, הגעת לסוף הקטלוג שלנו.'
                            })
                        }

                    }
                }
            }
        }else{
            selected_category.value = categories.value[0];
            selected_sub.value = selected_category.value.subs[0];
            if(categories_ref.value){
                categories_ref.value.selected_category = selected_category.value.uid;
                categories_ref.value.selected_sub = selected_sub.value;
            }
        }
        if(!products.value || products.value.length == 0){
            next_btn();
        }
    }

    const prev_btn = () =>{
        if(selected_category.value){
            let inx = categories.value.findIndex(cat => {
                return cat.uid == selected_category.value.uid
            })
            if(!selected_sub.value){
                if(inx != -1){
                    if(categories.value[inx].subs[0]){
                        selected_sub.value = categories.value[inx].subs[0];
                    }
                }
            }else{
                let inx_sub = categories.value[inx].subs.findIndex(sub => {
                    return sub == selected_sub.value
                })
                if(inx_sub != -1){
                    if(inx_sub > 0){
                        selected_sub.value = categories.value[inx].subs[inx_sub - 1]
                        if(categories_ref.value){
                            categories_ref.value.selected_sub = selected_sub.value;
                        }
                    }
                    else{
                        if(categories.value[inx - 1]){
                            selected_category.value = categories.value[inx - 1]
                            selected_sub.value = selected_category.value.subs[selected_category.value.subs.length - 1];
                            if(categories_ref.value){
                                categories_ref.value.selected_category = selected_category.value.uid;
                            }
                        }else{
                            Swal.fire({
                                icon: 'info',
                                title: 'תחילת קטלוג',
                            })
                        }

                    }
                }
            }
            if(!products.value || products.value.length == 0){
                prev_btn();
            }
        }
    }

    const selected_product = ref(null);

    onBeforeRouteLeave((to, from, next) => {
        if(!selected_product.value && !toggle_search.value){
            next()
        }else{
            selected_product.value = null;
            toggle_search.value = null;
            next(false)
        }
    })

    const search_string = ref('');
    
    const sales = ref(computed(() => {
        return store.getters.sales
    }));

    watch(selected_sub, () => {

        let doc = document.getElementById("products")
        if(doc){
            doc.scrollTo({top: 0, behavior: 'smooth'});
        }
    })

    let touchstartX = null;
    let touchendX = null;
    let touchendY = null;

    const attch_listeners = () =>{
        const grid = document.getElementById('products');
        if (grid) {
            grid.addEventListener(
              "touchstart",
              function(event) {
                touchstartX = event.changedTouches[0].screenX;
              },
              false
            );
            grid.addEventListener(
              "touchend",
              function(event) {
                touchendX = event.changedTouches[0].screenX;
                touchendY = event.changedTouches[0].screenY;
                handleGesture();
              },
              false
            );
        }
    }

    function handleGesture() {
      if (touchendY > 260) {
        if (touchendX + 200 < touchstartX) {
          swipeLeft();
        }
        if (touchendX > touchstartX + 200) {
          swipeRight();
        }
      }
    }

    const swipeLeft = () => {
        prev_btn();
    }

    const swipeRight = () => {
        next_btn();
    }

    onMounted(async() => {
        init_catalog();
        attch_listeners();

        //init flayer here
        flayers.value = await init_flayers()
        console.log(flayers.value);
        
    })
    // const check_flayer_dates = (flayer) => {
    //     const current_date = new Date(new Date().toISOString().split('T')[0])
    //     return current_date >= new Date(flayer.start_date) && current_date <= new Date(flayer.end_date)
    // }

    watch(selected_sub,()=>{
        if(selected_sub.value && flayers.value){
            const index = flayers.value.findIndex(f=>f.sub_categoty == selected_sub.value)
            if(index!=-1){
                selected_flayer_urls.value = flayers.value[index].flayer_images
                show_flayer.value = true
            }
        }
    })

    const open_sale = (e) => {
        selected_product.value = e;
    }
    
    const change_product = (e) => {
        selected_product.value = null;
        setTimeout(() => {
            selected_product.value = e
        }, 250);
        
    }
    
   

   
    return{
        toogle_menu, search_string, selected_category, selected_sub, select_category,select_sub,
        products, selected_product, next_btn,prev_btn, categories_ref, user, sales, open_sale, toggle_search,
        search_prod_string, product_search_results, change_product,promotions,handle_gift,show_promotions_dialog,
        selected_edit_item,flayers,show_flayer,selected_flayer_urls,is_cosher,stam

    }
}
}
</script>

<style scoped>
.catalog{
    position: relative;
    width: 100%;
    height: 100%;
}
.gift{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 100px;
    height: 100px;
    z-index: 10;
}

.gift img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

}
.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.title{
    position: relative;
    width: 100%;
    height: 50px;
    text-align: center;
    color: var(--yellow);
    background: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
.categories-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    height: fit-content;
    width: fit-content;
    background: var(--main);
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    border-radius: 7px;
}
.search-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    height: fit-content;
    width: fit-content;
    background: var(--main);
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    border-radius: 7px;
}
.categories-menu{
    width: 375px;
    height: calc(100% - 50px);
    overflow-y: auto;
    position: absolute;
    top: 50px;
    right: 0;
    background: var(--secondary);
    z-index: 5;
}
.products-grid{
    position: absolute;
    top: 50px;
    right: 0;
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
    z-index: 1;
}
.search-bar{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: var(--alert-purple);
}
.selected-sub{
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--warning);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0px 5px 0px 4px;
    transition: 0.25s;
}
.not-selected-sub{
    width: calc(100%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: var(--warning);
    transition: 0.25s;
}
.products{
    position: relative;
    width: 100%;
    height: calc(100% - 50px - 5px);
    display: grid;
    grid-auto-rows: 350px;
    grid-template-columns: repeat(1, 100%); /* Default to 1 column */
    gap: 5px;
    overflow: hidden;
    overflow-y: auto;
    transition: 0.25s;
    padding: 10px;

}

/* Tablet: 3 columns */
@media (min-width: 400px) { /* Adjust 600px based on your tablet breakpoint */
    .products {
        grid-template-columns: repeat(2, calc(100% / 2));
    }
}

/* PC: 4 columns */
/* @media (min-width: 1024px) { 
    .products {
        grid-template-columns: repeat(3, calc(100% / 3));
    }
} */


.next-sub-btn,.is-not-cosher-btn,.is-cosher-btn{
    width: 80px;
    height: 100%;
    background: var(--success);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.471);
    cursor: pointer;
}
.is-not-cosher-btn{
    left: 85px;
    background: var(--warning);
}
.is-cosher-btn{
    left: 170px;
    background: var(--blue);
}

@media screen and (max-width: 600px) {
    .is-not-cosher-btn{
        display: none;
    }
    .is-cosher-btn{
       display: none;
    }
}

.prev-sub-btn{
    width: 80px;
    height: 100%;
    background: var(--success);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.471);
    cursor: pointer;
}
.empty-cat{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    color: white;
}
.empty-cat img{
    width: 100px;
    height: auto;
    margin-bottom: 25px;
}
.cat-name{
    padding: 10px;
    width: fit-content;
    min-width: 130px;
    background: var(--danger);
    font-size: 26px;
    border-radius: 10px;
    text-align: center;
    text-shadow: 0 0 3px black;
    margin-bottom: 25px;
}
.search-panel{
    position: absolute;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.753);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.search-main{
    position: relative;
    width: 100%;
    max-width: 720px;
    height: 100%;
    max-height: 600px;
    background-color: var(--secondary);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.384);
    overflow-y: auto;
}
.search-title{
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--yellow);
    font-size: 24px;
    background-color: var(--main);
}
.close-search{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--secondary);
    color: var(--danger);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    cursor: pointer;
}
.search-bar{
    width: 100%;
    height: 50px;
    padding: 5px;
}
.item-result-line{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background: var(--main);
    margin-bottom: 5px;
    color: white;
}
.item-res-image{
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 5px;
}
.item-res-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.item-res-data{
    width: calc(100% - 60px - 60px);
    height: 100%;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.open-res-item{
    width: 60px;
    height: 100%;
    background: var(--success);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.692);
    cursor: pointer;
    border-radius: 5px;
}

/* @media only screen and (max-width: 1400px) {
    .products{
        grid-template-columns: repeat(2, calc(50% - 8px));
    }
}

@media only screen and (max-width: 850px) {
    .products{
        grid-template-columns: repeat(3, calc(50% - 8px));
    }
}
@media only screen and (max-width: 600px) {
    .products{
        grid-template-columns: 98%;
    }
    .selected-sub{
        font-size: 13px;
    }
} */

</style>