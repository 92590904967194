<template>
    <div class="categories" id="categories-wrapper">
        <div class="sales-btn" @click="select_sales">
            <i class="material-icons" style="margin-left: 5px;">sell</i>
            <p>מבצעים</p>
        </div>
        <template v-for="category in categories" :key="category.uid">
            <div class="category" @click="selected_category = category.uid ,selected_sub=null" :id="category.uid">
                <i class="material-icons" style="margin-left: 5px;">radio_button_checked</i>
                <p>{{category.name}}</p>
            </div>
            <div class="subs slide-in-right" v-if="selected_category && selected_category == category.uid">
                <template v-for="sub in category.subs" :key="sub">
                    <div class="sub-category" @click="selected_sub = sub" :id="sub">
                        <i class="material-icons" style="margin-left: 5px;">toll</i>
                        <p :class="check_current_sub(sub)">{{sub}}</p>
                    </div>
                </template>
                <div class="seperator"></div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core'
import store from '../../store'
export default {
emits:["select_category", "select_sub"],
props: ["selected_category", "selected_sub"],
setup(props, {emit}){
    const categories = ref(computed(() => {
        let cats =  store.getters.departments
        if(cats){
            cats.forEach(cat => {
                cat.subs = cat.subs.filter(sub => {
                    return store.state.t_subs.includes(sub)
                })
            })
            return cats
        }
    }))

    const selected_category = ref(null)
    if(props.selected_category){
        selected_category.value = props.selected_category.uid
    }

    const selected_sub = ref(null)
    if(props.selected_sub){
        selected_sub.value = props.selected_sub
    }

    watch(selected_category, () => {
        if(selected_category.value){
            let inx = categories.value.findIndex(cat => {
                if(selected_category.value.uid){
                    return cat.uid == selected_category.value.uid
                }else{
                    return cat.uid == selected_category.value
                }
                
            })
            if(inx != -1){
                selected_sub.value = categories.value[inx].subs[0];
                check_current_sub();
            }
            emit('select_category', selected_category.value)
            check_current_category();
        }
    })

    watch(selected_sub, () => {
        emit('select_sub', selected_sub.value)
        check_current_sub();
    })

    const check_current_sub = () => {
        let el = document.getElementsByClassName('sub-category')
        if(el){
            el.forEach(sub => {
                if(sub.id == selected_sub.value){
                    sub.classList.add('selected-sub')
                    scroll_to_category();
                }else{
                    sub.classList.remove('selected-sub')
                }
            })
        }
    }

    const check_current_category = () => {
        let el = document.getElementsByClassName('category')
        if(el){
            el.forEach(cat => {
                if(cat.id == selected_category.value){
                    cat.classList.add('selected-sub')
                }else{
                    cat.classList.remove('selected-sub')
                }
            })
        }
    }

    const scroll_to_category = () => {
        let el = document.getElementsByClassName('sub-category')
        let wrapper = document.getElementById('categories-wrapper')
        if(el && wrapper){
            const inx = Array.from(el).findIndex(cat => {
                return cat.id == selected_sub.value
            })
            if(inx != -1){
                setTimeout(() => {
                    el[inx].scrollIntoView({behavior: 'smooth', block: 'center',});
                }, 100);
            }
        }
    }

    const select_sales = () => {
        selected_category.value = null;
        selected_sub.value = null;
        emit("select_category", null)
    }
    onMounted(() => {
        if(props.selected_category){
            selected_category.value = props.selected_category.uid
            check_current_category();
        }        
        if(props.selected_sub){
            selected_sub.value = props.selected_sub
            check_current_sub();
        }      
        scroll_to_category();  
    })

    return{
        categories, selected_category, selected_sub, check_current_sub,
        select_sales,
    }
}
}
</script>

<style scoped>
.categories{
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.category{
    flex-shrink: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-shrink: 0;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 20px;
    color: var(--light);
    transition: 0.25s;
}
.category:hover{
    color:var(--yellow)
}
.category:hover i{
    font-size: 30px;
}
.category:hover p{
    transition: 0.25s;
    margin-right: 5px;
    font-size: 24px;
}
.category i {
    font-size: 12px;
    transition: 0.25s;
}
.subs{
    width: 100%;
    height: fit-content;
    padding: 5px 15px 0 5px;
}
.sub-category{
    width: 100%;
    height: 35px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    flex-shrink: 0;
    transition: 0.25s;
    cursor: pointer;
    color: rgb(199, 222, 243);
}
.sub-category i {
    font-size: 12px;
    transition: 0.25s;
}
.sub-category:hover{
    color:var(--warning)
}
.sub-category:hover i{
    font-size: 26px;
}
.selected-sub{
    color: var(--success);
}
.seperator{
    width: 100%;
    border: 1px solid var(--main);
    border-radius: 50%;
}
.sales-btn{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 20px;
    color: var(--yellow);
    cursor: pointer;
    transition: 0.25s;
    background: var(--alert-purple);
    border-radius: 5px;
    padding: 10px 0 10px 0;
}
.sales-btn:hover{
    color: var(--success);
    font-size: 26px;
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


</style>