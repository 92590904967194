<template>
    <div class="item scale-in-center">
        <div class="sale-images" v-if="sale.products">
            <div class="date-end">
                <i class="material-icons" style="font-size:16px; margin-left:5px;"> hourglass_empty</i>
                {{new Date(sale.end_date).toLocaleDateString('he')}}
            </div>
            <template v-for="product in sale.products" :key="product.makat">
                <img :src="product.image" alt="" :class="sale_image_class">
            </template>
        </div>
        <div class="sale-label">
            {{sale.label}}
        </div>
        <div class="description">
            {{sale.description}}
        </div>
        <div class="add-btn">
            <el-button type="primary" style="width:100%;" @click="open_item">הוסף להזמנה</el-button>
        </div>
        
    </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import { projectFirestore } from '../../firebase/config'
import store from '../../store'
import {not_loging_msg} from '../../Methods/Msgs'
export default {
props:["sale"],
emits:["open_sale"],
setup(props, {emit}){
    
    const get_items = async () => {
        if(props.sale && props.sale.makats_arr){
            props.sale.products = [];
            for(let i =0; i< props.sale.makats_arr.length; i++){
                let makat = props.sale.makats_arr[i]
                const doc = await projectFirestore.collection('Products').doc(makat).get()
                if(doc.exists){
                    props.sale.products.push(doc.data())
                }
            }
        }
    }

    const open_item = () => {
        if(store.state.user){
            let inx = store.state.products.findIndex(itm => {
                return itm.makat == props.sale.products[0].makat
            })
            emit("open_sale", store.state.products[inx])
        }else{
            not_loging_msg('משתמש לא מחובר','על מנת להוסיף מוצרים להזמנה יש להתחבר תחילה')
        }
    }

    const sale_image_class = ref(computed(() => {
        if(props.sale.products.length > 4){
            return 'over-imgs'
        }
        if(props.sale.products.length == 4){
            return 'four-imgs'
        }
        if(props.sale.products.length == 3){
            return 'three-imgs'
        }
        if(props.sale.products.length == 1){
            return 'one-imgs'
        }
        if(props.sale.products.length == 2){
            return 'two-imgs'
        }
    }))

    onMounted(() => {
        get_items();
    })

    return{
        sale_image_class, open_item
    }
}
}
</script>

<style scoped>
.item{
    position: relative;
    background: white;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    height: 100%;
}
.sale-images{
    position: relative;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.three-imgs{
    max-width: 32%;
    max-height: 100%;
    border-radius: 5px;
    margin-left: 5px;
}
.four-imgs{
    max-width: 23%;
    max-height: 100%;
    border-radius: 5px;
    margin-left: 5px;
}
.over-imgs{
    max-width: 20%;
    max-height: 100%;
    border-radius: 5px;
    margin-left: 5px;
}
.two-imgs{
    max-width: 49%;
    max-height: 100%;
    border-radius: 5px;
    margin-left: 5px;
}
.one-imgs{
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.sale-label{
    width: 100%;
    height: 15%;
    background: var(--danger);
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.747);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: center;
}
.description{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--success);
    font-size: 18px;
    color: white;
    white-space: nowrap;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}
.add-btn{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.date-end{
    position: absolute;
    top: 5px;
    left: 5px;
    width: fit-content;
    padding: 0 5px 0 5px;
    background: var(--purple);
    color: white;
    z-index: 2;
    border-radius: 3px;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.order-amount{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--yellow);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px rgba(128, 128, 128, 0.692);
    flex-direction: column;
}
</style>