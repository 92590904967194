<template>
  <div class="display-flayer">
    <div @click="$emit('close')" class="exit-btn">
        <i class="material-icons">close</i>
    </div>
    <template v-for="url in flayer_images" :key="url">
        <img :data-src="url" class="blur" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/tiny_image_375x375.jpg?alt=media&token=e9bac415-dfb3-4307-bc10-effbfe571647">
    </template>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'

export default {
    components:{},
    props:['flayer_images'],
    emits:['close'],
    setup(){

        const handle_load_img = (e) => {
            console.log(e);
        }

        const load_image = (img) => {
            const bigImage = document.createElement('img')
            bigImage.onload = function(){
                img.src = this.src
                img.className = "noblur"
                img.removeAttribute('data-src')
            }
            bigImage.src = img.dataset.src
        }

        onMounted(()=>{
            const images = document.querySelectorAll('[data-src]')
            images.forEach((img)=>{
                load_image(img)
            })
        })
        
        return{
           handle_load_img
        }
    }
}
</script>

<style scoped>
    .display-flayer{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        overflow: hidden;
        overflow-y: auto;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
    .display-flayer img{
        width: 100%;
        margin-bottom: 5px;
    }
    .blur{
        filter: blur(10px);
        transition: all 1s;
    }
    .noblur{
        transition: all 1s;
    }
    
</style>