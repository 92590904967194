<template>
    <div class="confirmation-dialog">
        <div class="confirmation-dialog-header">
            <h2>{{promotion.instructions}}</h2>
        </div>
        <div v-loading="is_pending" class="confirmation-dialog-image">
            <i v-if="!url" @click="handle_upload" class="material-icons camera-icon">add_a_photo</i>
            <img v-if="url" :src="url">
            <div @click="handle_delete_image" v-if="url" class="delete-btn">
                <i class="material-icons">delete</i>
            </div>
        </div>
        <div class="confirmation-dialog-describe">
            <h2>{{promotion.title}}</h2>
        </div>
        <div class="confirmation-dialog-options">
            <el-button @click="handle_add_gift_to_client" type="success">אישור</el-button>
            <el-button @click="handle_cancel" type="danger">ביטול</el-button>
        </div>
        <input type="file" id="upload_file" v-show="false" @change="handleChange">
    </div>
</template>

<script>
import {after_client_implement_gift,update_client_fulfillment} from '../../../Methods/Promotions_funcs'
import {alert,slide_pop_error} from '../../../Methods/Msgs'
import use_general_storage from '../../../Methods/use_general_storage'
import { computed, ref } from '@vue/runtime-core'
import store from '../../../store'
export default {
    emits:['finish','cancel'],
    props:['promotion'],
    setup(props,{emit}){
        const is_pending = ref(false)
        const client_name = ref(computed(()=>{
            return store.state.client.client_name
        }))
        const agent_name = ref(computed(()=>{
            return store.state.user.display_name
        }))
        const client_id = ref(computed(()=>{
            return store.state.user.client
        }))

        const agent_id = ref(computed(()=>{
            return store.state.user.agent
        }))

        const {url,filePath,uploadImage,deleteImage} = use_general_storage()

        const handleChange = async(e)=>{
            const types = ["image/png", "image/jpeg", "image/jpg"];
            const selected = e.target.files[0];
            if (selected && types.includes(selected.type)) {
                is_pending.value = true
                await uploadImage(selected,`Promotions/${agent_id.value}/${client_id.value}`);
                is_pending.value = false

            }
            else{
                slide_pop_error('סוג קובץ זה אינו נתמך!')
            }
        }
        const handle_upload = ()=>{
            document.getElementById('upload_file').click()
        }

        const handle_delete_image = async()=>{
            await deleteImage(filePath.value)
            url.value = null
        }

        const validation = ()=>{
            if(!url.value) {
                slide_pop_error('עליך לצלם תמונה!!!')
                return false
            }else{
                return true
            }
        }

        const handle_add_gift_to_client = async()=>{
            if(validation()){
                await after_client_implement_gift(props.promotion)
                await update_client_fulfillment(props.promotion,{
                    client:client_id.value,
                    clinet_name:client_name.value,
                    agent:agent_id.value,
                    agent_name:agent_name.value,
                    image:{
                        url:url.value,
                        filePath:filePath.value
                    },
                    created_at:new Date(),
                })
                alert('success','הצלחה'
                ,`המתנה ${props.promotion.title} נכנסה למימוש`)
                .then(()=>{
                    emit('finish')
                })
            }
        }

        const handle_cancel = ()=>{
            emit('cancel')
        }

        return{
            handle_add_gift_to_client,
            handle_cancel,
            handle_upload,
            handleChange,
            url,
            is_pending,
            handle_delete_image
        }
    }
}
</script>

<style scoped>
    .confirmation-dialog{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        background: #fff;
        border-radius: 0 0 10px 10px;
    }
    .confirmation-dialog-header{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
    .confirmation-dialog-image{
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .confirmation-dialog-image img{
        max-width: 100%;
        max-height: 100%;
    }
    .confirmation-dialog-describe{
        width: 100%;
        height: 20%;
        border-bottom: 1px solid lightgray;
        padding: 0 5px;
    }
    .confirmation-dialog-options{
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    .camera-icon{
        font-size: 150px;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
    }
    .delete-btn{
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: 0;
        left: 5px;
        background: var(--danger);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 17;
        cursor: pointer;
    }
    .delete-btn i{
        font-size: 28px;
    }
</style>