<template>
    <div class="confirmation-dialog">
        <div class="confirmation-dialog-header">
            <h1>ברכות!!!</h1>
        </div>
        <div class="confirmation-dialog-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/gifs%2Fgift-gif-14.gif?alt=media&token=9460f109-cf1f-4677-a134-c99c0ad28c7e">
        </div>
        <div class="confirmation-dialog-describe">
            <h2>{{promotion.title}}</h2>
            <h3 v-if="promotion.kupon_descripsion">תיאור הקופון: {{promotion.kupon_descripsion}}</h3>
        </div>
        <div class="confirmation-dialog-options">
            <el-button @click="handle_add_gift_to_client" type="success">אישור</el-button>
            <el-button @click="handle_cancel" type="danger">ביטול</el-button>
        </div>
    </div>
</template>

<script>
import {after_client_implement_gift,update_client_fulfillment} from '../../../Methods/Promotions_funcs'
import {alert} from '../../../Methods/Msgs'
import { computed, ref } from '@vue/runtime-core'
import store from '../../../store'

export default {
    emits:['finish','cancel'],
    props:['promotion'],
    setup(props,{emit}){
        const client_name = ref(computed(()=>{
            return store.state.client.client_name
        }))
        const agent_name = ref(computed(()=>{
            return store.state.user.display_name
        }))

        const client_id = ref(computed(()=>{
            return store.state.user.client
        }))

        const agent_id = ref(computed(()=>{
            return store.state.user.agent
        }))
        
        const handle_add_gift_to_client = async()=>{
            await after_client_implement_gift(props.promotion)
            await update_client_fulfillment(props.promotion,{
                client:client_id.value,
                clinet_name:client_name.value,
                agent:agent_id.value,
                agent_name:agent_name.value,
                image:null,
                created_at:new Date(),
            })
            alert('success','הצלחה'
            ,`המתנה ${props.promotion.title} נכנסה למימוש`)
            .then(()=>{
                emit('finish')
            })
        }

        const handle_cancel = ()=>{
            emit('cancel')
        }

        console.log(client_id.value);

        return{handle_add_gift_to_client,handle_cancel}
    }
}
</script>

<style scoped>
    .confirmation-dialog{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        background: #fff;
        border-radius: 0 0 10px 10px;
    }
    .confirmation-dialog-header{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
    .confirmation-dialog-image{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .confirmation-dialog-image img{
        max-width: 100%;
        max-height: 100%;
    }
    .confirmation-dialog-describe{
        width: 100%;
        height: 20%;
        border-bottom: 1px solid lightgray;
        padding: 0 5px;
    }
    .confirmation-dialog-options{
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

    
</style>